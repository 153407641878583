/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import './loading.css';

function Loading(): JSX.Element {

    const [loading, setLoading] = useState(false)

    return (
        <div>
            <div className='loading-container'>
                <div className=''>
                    <h5 className="font-orbitronRegular mb-3">Please wait...</h5>
                    <div className="item"></div>
                    <div className="item"></div>
                    <div className="item"></div>
                    <div className="item"></div>
                </div>
            </div>

            {/* <div className={"pageLoading d-flex align-items-center justify-content-center text-center"}        >
                <div>
                    <h5 className="font-orbitronRegular mb-3">Please wait...</h5>
                    <div className="spinner-border mb-3" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div> */}
        </div>

    )
}
export default Loading
