/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Globe from "../../../asset/img/globe-movo.png";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import arrow from "../../../asset/img/arrow.svg";

function Delegate({ currentAPR }: any) {

    return (
        <section>
            {/* Delegate to MovoScan */}
            <div className="bg-dark mb-3">
                <div className="row delegate mx-lg-5">
                    <div className="col-lg-8">
                        <span className="validator">MOVO Validator</span>
                        <h1 className="lightText">Stake your MOVO to become our validator</h1>
                        <p className="lightText">Staking is a public good for the Movo ecosystem. You can help secure the network and earn rewards in the process.</p>
                        <h2 className="lightText">What is Validator?</h2>
                        <p className="lightText">It powers the blockchain network by processing transactions and signing blocks.</p>
                        <h2 className="lightText">WHAT ARE THE BENEFITS OF BEING A VALIDATOR?</h2>
                        <p className="lightText">Validators receive a portion of the fees generated by the network. This can be substantial considering the massive number of users of the MSC. Average daily rewards of validators have once reached a high of MOVO.</p>
                        <p className="lightText">Apart from financial returns <b style={{ textDecoration: 'underline' }}>(current APR: {currentAPR}%)</b>, validators make network decisions such as adjusting gas fees and proposals for protocol upgrades.</p>
                        <h2 className="lightText">How to stake MOVO to MovoScan?</h2>
                        <p className="lightText">To stake, you’ll need to create a MetaMask / WalletConnect wallet and have a minimum of 1 MOVO. Follow our step-by-step <a href="/">guide</a>.</p>
                        <a href="#blockStake">
                            <button className="btn">
                                Stake Now
                                &ensp;<img src={arrow} alt="" />
                            </button>
                            {/* <button className="btn delegate">Stake Now <FontAwesomeIcon icon={faLongArrowAltRight} /></button> */}
                        </a>
                    </div>
                    {/* <div className="col-lg- d-xs-none">
                            <div className="d-lg-flex flex-lg-row-reverse d-none">
                                <img src={Globe} alt="" style={{ width: '39rem' }} className="globe" />
                            </div>
                        </div> */}
                </div>
            </div>
        </section>
    );
}

export default Delegate;