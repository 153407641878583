/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './App.css';
import "./asset/css/style.css";
import Navbar from "./component/screen/navbar/navbar"
import Stake from "./component/screen/stake/stake"
import Delegate from "./component/screen/delegate/delegate"
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import Leaderboard from './component/screen/leaderboard/leaderboard';

function App() {
    const [web3, setWeb3] = useState(Web3.givenProvider);
    const [provider, setProvider] = useState();
    const [accountAddress, setAccountAddress] = useState('');
    const [accountBalance, setAccountBalance] = useState(0);
    const [recentMovoProfit, setrecentMovoProfit] = useState(0)
    const [currentAPR, setcurrentAPR] = useState(0)

    useEffect(() => {
        addNetwork()
    }, [])

    useEffect(() => {
        getAccountBalance(accountAddress);
    }, [accountAddress])

    // @ts-ignore
    useEffect(async () => {
        setProvider(Web3.givenProvider);
        await setProviderAndWeb3(Web3.givenProvider);
    }, [])

    // @ts-ignore
    useEffect(async () => {
        if (window.ethereum) {
            try {
                // check if the chain to connect to is installed
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x27' }], // chainId must be in hexadecimal numbers
                });
            } catch (error) {
                // This error code indicates that the chain has not been added to MetaMask
                // if it is not, then install it into the user MetaMask
                // @ts-ignore
                if (error.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: '0x27',
                                    rpcUrl: 'https://data-seed-prebsc-2-s3.binance.org:8545/',
                                },
                            ],
                        });
                    } catch (addError) {
                        console.error(addError);
                    }
                }
                console.error(error);
            }
        } else {
            // if no window.ethereum then MetaMask is not installed
            alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        }
    })

    // @ts-ignore
    useEffect(async () => {
        if (provider) {
            // @ts-ignore
            provider.on("accountsChanged", async (accounts: string[]) => {
                // @ts-ignore
                if (accounts[0]) {
                    const address = accounts[0].toLowerCase();
                    setAccountAddress(address);
                    await getAccountBalance(address);
                } else {
                    setAccountAddress('');
                    setAccountBalance(0);
                }
            });
        }
    }, [provider])

    // @ts-ignore
    useEffect(async () => {
        if (provider) {
            // @ts-ignore
            provider.on("chainChanged", async (chainId: number) => {
                /* test net "0x3" main net "0x1" */
                if (chainId.toString() !== "0x27") {
                    setAccountAddress('');
                    setAccountBalance(0);
                } else {
                    if (web3) {
                        const accountAddress = await web3.eth.getAccounts();
                        if (accountAddress[0]) {
                            const address = accountAddress[0].toLowerCase();
                            setAccountAddress(address);
                            await getAccountBalance(address);
                        }
                    }
                }
            });
        }
    }, [provider])

    const web3Modal = new Web3Modal({
        cacheProvider: false,
        providerOptions: {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: "709a9fa5bc9543cabaecd8064b7a38f5",
                }
            }
        },
        disableInjectedProvider: false,
    });

    const addNetwork = () => {
        const params = [
            {
                chainId: '0x27', //'39',
                chainName: 'Movo Smart Chain Testnet',
                nativeCurrency: {
                    name: 'MOVO',
                    symbol: 'MOVO',
                    decimals: 18
                },
                rpcUrls: ['https://rpc-testnet.movoscan.com/'],
                blockExplorerUrls: ['https://testnet.movoscan.com/']
            }
        ]
        if (typeof window['ethereum'] !== 'undefined') {
            window['ethereum']
                .request({ method: 'wallet_addEthereumChain', params })
                .then((res: any) => {
                    // console.log('Changed wallet success')
                    // console.log(res)
                })
                // @ts-ignore
                .catch(error => {
                    console.log('Error', error.message)
                })
        }
    }

    const getAccountBalance = async (address: string) => {
        // @ts-ignore
        if (web3.eth && address) {
            // @ts-ignore
            const accountBalance = await web3.eth.getBalance(address);
            setAccountBalance(Number(ethers.utils.formatUnits(accountBalance, 18)));
        }
    }

    async function onConnect() {
        let pd;
        try {
            if (!accountAddress) {
                web3Modal.clearCachedProvider();
                pd = await web3Modal.connect();
                setProvider(pd);
            }
        } catch (e) {
            console.log("Could not get a wallet connection", e);
            return;
        }

        if (pd) {
            await setProviderAndWeb3(pd);
        }
    }

    const setProviderAndWeb3 = async (provider: any) => {
        const web = new Web3(provider);
        setWeb3(web);
        const accountAddresses = await web.eth.getAccounts();
        if (accountAddresses[0]) {
            setAccountAddress(accountAddresses[0].toLowerCase());
            await getAccountBalance(accountAddresses[0].toLowerCase());
        }
    }

    return (
        <>
            <Navbar setcurrentAprProp={setcurrentAPR} accountAddress={accountAddress} accountBalance={accountBalance} onConnect={onConnect} setrecentMovoProfit={setrecentMovoProfit} />

            <div className="wrapper">
                <Delegate currentAPR={currentAPR} />

                <Stake accountAddress={accountAddress} accountBalance={accountBalance} onConnect={onConnect} getAccountBalance={getAccountBalance} recentMovoProfit={recentMovoProfit} />

                <Leaderboard accountAddress={accountAddress} accountBalance={accountBalance} />

                {/* <div className="row mb-5 pt-5">
                    <div className="col">
                        <Stake accountAddress={accountAddress} accountBalance={accountBalance} onConnect={onConnect} getAccountBalance={getAccountBalance} recentMovoProfit={recentMovoProfit} />
                    </div>
                </div> */}
                {/* <div className="row">
                    <div className="col">
                        <Leaderboard accountAddress={accountAddress} accountBalance={accountBalance} />
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default App;